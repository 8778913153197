.h-flex {
    display: flex;
    flex-direction: row;
}
.v-flex {
    display: flex;
    flex-direction: column;
    align-items: start;
}

dialog#styles-configuration-dialog {
    height: 100%;
    padding: 0;
    width: 100%;

    .dialog-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .dialog-content {
        overflow-y: auto;
        flex-grow: 1;
    }

    #styles-configuration-dialog-fields {
        display: flex;
        flex-wrap: wrap;
    }

    .styles-configuration-section {
        min-width: min(100%, 40em);
    }

    #styles-configuration-dialog-fields label {
        width: 100%;

        input[type="range"] {
            width: 100%;
        }
    }

    h5 {
        margin: 0.5em 0 0 0;
        font-weight: bold;
    }


    .styles-configuration-items {
        display: inline-block;
        align-items: center;
        width: 100%;
        margin: 0 1em 0 1em;
    }

    .dialog-content {
        padding: 1em;
    }

    header {
        padding: 1em;
        border-bottom: 1px solid Gainsboro;
    }

    footer {
        display: flex;
        padding: 1em;
        justify-content: flex-end;
        border-top: 1px solid Gainsboro;
    }

    #styles-configuration-dialog-close-button {
    }

    svg {
        flex-shrink: 0;
        background: linear-gradient(45deg, lightgrey 25%, transparent 25%, transparent 75%, lightgrey 75%) 0 0/20px 20px,
                    linear-gradient(45deg, lightgrey 25%, white 25%, white 75%, lightgrey 75%) 10px 10px/20px 20px;
    }
}