$background-color : #FFFFDF;
$highlight-color : #CC6633;

$toast-font-size: 1.1rem !default;

@import "../node_modules/bootstrap/scss/bootstrap";

html {
    height:100%;
    padding: 0;
    margin: 0;
    font-size: 13px;
}

body {
    background-color: $background-color;
    font-family: verdana,arial,serif;
    /*font-size: 11px;
    line-height: 1.2;*/
    padding: 0px;
    margin: 0px;
    height: 100%;
    user-select: none;
}

a {
    color: $highlight-color;
}

h2 {
    font-weight: normal;
    color: $highlight-color;
    font-size: 1.5em;
    margin: 0;
}

.container label {
    width:100%;
}
.container button {
    width:100%;
}

.container .btn-group {
    width:100%;
}
table .btn-inline-icon {
    width: 3em;
}
td>button {
    width: 3em;
}
th>button {
    width: 3em;
}

.form-floating select.form-select {
    padding-top: 1.5rem;
}

.btn.btn-inline-icon:disabled, .btn.btn-inline-icon:hover {
    background-color: rgba(0,0,0,0);
}

/* Don't show hover */
.btn-outline-primary:hover {
    color: #0d6efd;
    background-color: #fff;
}

.btn-check:checked+.btn-outline-primary:hover {
    color: #fff;
    background-color: #0d6efd;
}

.btn-outline-info:hover {
    color: #0dcaf0;
    background-color: #fff;
}

.btn-check:checked+.btn-outline-info:hover {
    color: black;
    background-color: #0dcaf0;
}

// .dropdown-toggle:after { content: none }

.btn>i {
    font-size:1.5em;
}


.modal-bottom {
    bottom: 0;
    top: auto;
    position: fixed;
    left: 0;
    right: 0;
}

.modal.fade-bottom .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0,0);
}

.dropdown-menu {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}

tr .on_selected {
  display: none;
}
tr.selected .on_selected {
  display: inline-block;
}

tr.selected {
  background-color: #cfe2ff;
}

tr.selected td {
  background-color: #cfe2ff;
}

body:not(.with_cloud) .if_cloud {
    display: none;
}

dialog::backdrop {
    background: rgba(0, 0, 0, 0.5);
}

th[data-sort] {
    color: var(--bs-primary-text);
    text-decoration: underline;
}
th[data-sorted] {
    color: var(--bs-info-text);
}

th[data-sorted="asc"]::after {
    content:"▲";
}
th[data-sorted="desc"]::after {
    content:"▼";
}


#overlay-action {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000A;
    z-index: 5000;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .overlay-action-content {
        background-color: #AAA;
        color: black;
        padding: 2em;
        border: 2px solid #F44;

    }

}

#sub-cluster-list-open {
    position: absolute;
    top: 10px;
    left: 60px;
    z-index: 1000;
    padding-left: env(safe-area-inset-left);

    button {
        border-radius: 2px;
        border-radius: 2px;
        border: 2px solid #0003;
        color: black;
    }

    button.btn {
        background-color: white;
    }

    button.btn:hover, button.btn.show {
        background-color: #f4f4f4;
    }

    span.dropdown-item {
        cursor: pointer;
    }

    span.dropdown-item:hover {
        background-color: #AAA;
    }

}

#modal-routes .modal-content {
    height: 100%
}

@import './views/routes-list/routes-list';
@import './modules/privacy/privacy';


$sidebar-width: 350px;
@import './views/sidebar-app/sidebar-app';


#map-container {
    flex: 1;
    width: 100%;
    height: 100%;
}

#main {
    display: flex;
    flex: auto;
    height: 0;
    overflow-y: auto;
    position: relative;
}

#header {
    display: flex;
    justify-content: space-between;
}

button.button-icon {
    cursor:pointer;
    width: 2em;
    height: 2em;
    align-items: center;
    justify-content: center;
    border: 2px solid #00000040;
    background-color: #ffffff40;
    font-size: 1.5rem;
}

button.button-icon:hover {
    border: 2px solid #00000080;
    background-color: #ffffff80;
}


#header {
    left: 0;
    font-size: 1rem;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
}

#elevation_profile {
    position: absolute;
    z-index:999;
    bottom:0;
    height: min(200px, 25%);
    left: max(50px, 50% - 400px);
    right: 0;

    .sidebar-autohide & {
        right: max(50px, 50% - 400px);
    }
}

#main .beautify-marker {
    font-family: sans-serif,
}


#sidebar-app label i {
    line-height: 1.5;
}