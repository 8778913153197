#privacy_dialog {
    .privacy_elements {
        margin-left: 2ex;
    }

    .privacy_title {
        font-weight: bold;
        color: blue;
    }

    #privacy_dialog_fields label {
        margin-left: 1ex;
        font-weight: bold;
    }
}

