$sidebar-lock-min-width: calc($sidebar-width + 200px);

#sidebar-app {
    --sidebar-width: min( #{$sidebar-width} , 100%); /* var(--sidebar-width) */
    display: flex;
    flex-direction: column;
    min-height: 100svh;
    container-name: sidebarApp;
    container-type: inline-size;
    overflow: hidden;
}

.sidebar-left {
    --sidebar-wrapper-left: 0;
    --sidebar-wrapper-hidden-left: calc( 0px - var(--sidebar-width));
    --sidebar-wrapper-right: inherit;
    --sidebar-wrapper-hidden-right: inherit;
    --content-margin-left: var(--sidebar-width);
    --content-margin-right: inherit;
}

.sidebar-right {
    --sidebar-wrapper-left: inerit;
    --sidebar-wrapper-right: 0;
    --content-margin-right: var(--sidebar-width);
    --content-margin-left: inherit;
    --sidebar-wrapper-hidden-right: calc( 0px - var(--sidebar-width));
    --sidebar-wrapper-hidden-left: inherit;
}

#sidebar-wrapper {
    display: block;
    width: min(100%, var(--sidebar-width));
    flex-shrink: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 2000;
    box-shadow: -5px 0px 5px #00000040;
    left: var(--sidebar-wrapper-left);
    right: var(--sidebar-wrapper-right);
    animation-duration: .2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
}

#sidebar-app.sidebar-autohide {
    #sidebar-wrapper {
        box-shadow: 0 0;
        left: var(--sidebar-wrapper-hidden-left);
        right: var(--sidebar-wrapper-hidden-right);
    }
    #sidebar-wrapper.slide-in {
        animation-name: slide-in;
    }
    #sidebar-wrapper.slide-out {
        animation-name: slide-out;
    }
}

#sidebar {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    position: absolute;
    font-size: 1rem;
    background-color: #FFFFFF;
}

#content-overlay.slide-out {
    display: none;
}

#content-overlay.slide-in {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1009;
    opacity: .5;
    background-color: #000;
}

#content {
    display: flex;
    flex: auto;
    flex-flow: column;
    height: 0;
    width: calc(100% - var(--sidebar-width));
    margin-left: var(--content-margin-left);
    margin-right: var(--content-margin-right);
}

.sidebar-autohide {
    #content {
        width: 100%;
        margin-left: var(--sidebar-wrapper-left);
        margin-right: var(--sidebar-wrapper-right);
    }
}

#sidebar-header, #sidebar-footer {
    display: flex;
    background-color: #cfe2ff;
    color: #FFFFFF;
    align-items: center;
    text-align: center;
    font-size: 1.2rem;
    justify-content: space-between;
}

#sidebar-footer {
    padding-bottom: env(safe-area-inset-bottom);
}

.leaflet-bottom {
    padding-bottom: env(safe-area-inset-bottom);
}

.leaflet-left {
    padding-left: env(safe-area-inset-left);
}

.sidebar-hideonlock {
    display: none;
}
.sidebar-showonlock {
    display: inherit;
}

@container sidebarApp (width < #{$sidebar-lock-min-width} ) {
    #sidebar-close  {
        display: inherit;
    }
    #sidebar-lock  {
        display: none;
    }
}
@container sidebarApp (width >= #{$sidebar-lock-min-width} ) {
    #sidebar-close  {
        display: inherit;
    }
    #sidebar-lock  {
        display: none;
    }

    .sidebar-autohide {
        #sidebar-close  {
            display: none;
        }
        #sidebar-lock  {
            display: inherit;
        }
    }
}

.sidebar-autohide {
    .sidebar-hideonlock {
        display: inherit;
    }
    .sidebar-showonlock {
        display: none;
    }
}

@keyframes slide-in {
    0% {
        left: var(--sidebar-wrapper-hidden-left);
        right: var(--sidebar-wrapper-hidden-right);
    }
    to {
        left: var(--sidebar-wrapper-left);
        right: var(--sidebar-wrapper-right);
    }
}

@keyframes slide-out {
    0% {
        left: var(--sidebar-wrapper-left);
        right: var(--sidebar-wrapper-right);
    }
    to {
        left: var(--sidebar-wrapper-hidden-left);
        right: var(--sidebar-wrapper-hidden-right);
    }
}


.btn-check:not(:checked) + .btn {
    background-color: white !important;
}

#sidebar-content {
    overflow: auto;
    flex-grow: 1;
}

#sidebar-open {
    display: none;

    .sidebar-autohide & {
        display: inherit;
    }
}